import React, { useState, useRef } from 'react';
import { CircularProgress, ButtonGroup, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from "@gisce/oficina-virtual-components";
import { useDispatch } from "react-redux";
import { GetApp } from "@mui/icons-material";
import { exportInvoice as exportInvoiceElectricity } from "./actions/invoices";
import { exportInvoice as exportInvoiceMultipoint } from "../../actions/invoicesMultipoint";
import { exportInvoice as exportInvoiceGas } from "./actions/invoicesGas";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export const DownloadButton = ({ invoiceId, type, options }) => {
  const dispatch = useDispatch();
  const downloadStatus = useSelector((state) => state.invoices[type].download_status[invoiceId]);
  const token = useSelector(state => state.auth.token);
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('pdf');

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleDownload = (option) => {
    setSelectedOption(option);
    if (type === "multipoint") {
      dispatch(exportInvoiceMultipoint(token, invoiceId, options[option]['type']));
    }
    if (type === "electricity") {
      dispatch(exportInvoiceElectricity(token, invoiceId, options[option]['type']));
    }
    if (type === "gas") {
      dispatch(exportInvoiceGas(token, invoiceId, options[option]['type']))
    }
    setOpen(false);
  }

  return (
    <>
      <ButtonGroup
        variant='contained'
        ref={anchorRef}
        aria-label='Button group with a nested menu'
        disabled={downloadStatus}
      >
        <Button 
          title={"Download invoice"}
          color={'primary'}
          variant={'contained'}
          onClick={() => handleDownload('pdf')}
          sx={{
            minWidth: Object.keys(options).length > 1 ? '100px' : '110px',
            maxWidth: Object.keys(options).length > 1 ? '260px' : '270px',
            fontSize: '12px'
          }}
        >
          {downloadStatus ? <CircularProgress size={20} sx={{marginRight: '5px'}} /> : <GetApp />}
          {downloadStatus ? options[selectedOption]['name'] : t('common:text.invoices_view_download_button')}
        </Button>
        { Object.keys(options).length > 1 &&
          <Button
            size='small'
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label='select download option'
            aria-haspopup='menu'
            onClick={() => setOpen((prevOpen) => !prevOpen)}
            color='primary'
          >
            <ArrowDropDownIcon />
          </Button>
        }
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {Object.keys(options).map((option) => (
                    option != 'pdf' && (
                      <MenuItem
                        key={option}
                        onClick={(event) => handleDownload(option)}
                      >
                        {options[option]['name']}
                      </MenuItem>
                    )
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
